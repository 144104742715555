.c-sec {
  padding-top: 90px;
  padding-bottom: 90px;
  @include mq(sp) {
    padding-top: 90 / $spDesign + vw;
    padding-bottom: 90 / $spDesign + vw;
  }
  &__txt {
    p {
      & + p {
        margin-top: 30px;
        @include mq(sp) {
          margin-top: 40 / $spDesign + vw;
        }
      }
    }
  }
  &__inner {
    max-width: 1020px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      padding-left: 3.3vw;
      padding-right: 3.3vw;
    }
  }
}