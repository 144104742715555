@charset "utf-8";
@mixin letter-spacing($tracking) {
  letter-spacing: $tracking / 1000 + em;
}
$transition: .3s;
$spDesign: 750 / 100;
$opacity: .75;
$breakpoints: (
  'pc': 'screen and (min-width: 768px)',//pc
  'sp': 'screen and (max-width: 767px)',//sp
) !default;

$colorParagraph:#303030;
$colorPrimary:#7ed2d9;
$colorSecondary:#edf9f9;
$colorAccent:#2f707e;
$white:#fff;
$colorAccentPink:#ea6676;
$colorAccentGreen:#7ed2d9;

$alls: (
  colorParagraph:#303030,
  colorPrimary:#7ed2d9,
  colorSecondary:#edf9f9,
  colorAccent:#2f707e,
  white:#fff,
  colorAccentPink:#ea6676,
  colorAccentGreen:#7ed2d9,
 );

$colors: (
  colorParagraph:#303030,
  colorPrimary:#7ed2d9,
  colorSecondary:#edf9f9,
  colorAccent:#2f707e,
  white:#fff,
  colorAccentPink:#ea6676,
  colorAccentGreen:#7ed2d9,
);


@each $name,$color in $colors {
  ._c-#{$name} {
    color: $color!important;
  }
  ._border-#{$name} {
    border: 3px $color solid;
  }
  ._bg-#{$name} {
    background-color: $color;
  }
  .fill-#{$name} {
    fill: $color;
  }

}

@each $name,$all in $alls {
  ._hover-#{$name} {
    &:hover {
      background: $all;
    }
  }
  ._hover-color-#{$name} {
    &:hover {
      color: $all;
    }
  }

}