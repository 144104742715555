.swiper {
  &-slide {
    overflow: hidden;
    @include mq(pc) {
      max-width: 300px;
      max-height: 300px;
    }
    @include mq(sp) {
      width: 40vw;
      max-height: 40vw;
    }
  }
  &-container {
    padding-bottom: 60px;
    @include mq(sp) {
      padding-bottom: 60/$spDesign + vw;
    }
  }
}