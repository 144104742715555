@charset "utf-8";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
  font-family: 'Noto Sans JP',sans-serif;
  @include mq(sp) {
    font-size: calc(100vw / 75);
  }
}

body {
  width: 100%;
  line-height: 1.5;
  color: $colorParagraph;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  position: relative;
  font-size: 1.5rem;
  line-height: 1.87;
  @include mq(sp) {
    min-width: auto;
    font-size: 2.6rem;
  }
}

a {
  color: $colorParagraph;
  text-decoration: none;
  transition: all .3s;
  // &:hover,
  // &:active,
  // &:focus {
  //   opacity: .5;
  // }
}
a[href^="tel:"] {
  cursor: default;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

.swiper-slide img{
  background-image: url(../img/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: center center;
}

small {
  font-size: 1em;
}

// IE11 SVGサイズ崩れハック
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$='.svg'] {
    width: 100%;
    height: auto;
  }
}
.sp {
  display: none;
  @include mq(sp) {
    display: block;
  }
}

.pc {
  @include mq(sp) {
    display: none;
  }
}

.c-inner {
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  @include mq(sp) {
    // padding-left: 4vw;
    // padding-right: 4vw;
  }
}