@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin boxDatePC($size) {
  display: flex;
  width: $size + px;
  height: $size + px;
  align-items: center;
  justify-content: center;
}

@mixin boxDateSP($size) {
  display: flex;
  width: $size + vw;
  height: $size + vw;
  align-items: center;
  justify-content: center;
}

@mixin vw_width($width) {
  width: $width /  (750 / 100) + vw;
}

@mixin arrowRight($size,$width,$color) {
  content:"";
  position: absolute;
  top: 50%;
  right: $size;
  width: $size;
  height: $size;
  border-top: $width + px solid $color;
  border-right: $width + px solid $color;
  transform: rotate(45deg) translateY(-50%);
}

@mixin fb_width($width) {
  flex-basis: $width;
  max-width: $width;
}