._flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  &-pc {
    @include mq(pc) {
      display: flex;
    }
  }
  &-between {
    &-pc {
      @include mq(pc) {
        justify-content: space-between;
      }      
    }
  }
  &-wrap {
    &-pc {
      @include mq(pc) {
        flex-wrap: wrap;
      }      
    }
  }
  &-center {
    justify-content: center;
    &-pc {
      @include mq(pc) {
        justify-content: center;
      }
    }
  }
  &-order {
    &-00 {
      @include mq(pc) {
        order: -1;
      }
    }
  }
}
._bold {
  font-weight: 700;
}

._lang-en {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

._indent {
  text-indent: -1em;
  padding-left: 1em;
}

._center, ._ta-c {
  text-align: center !important;
}
._center {
  &-pc {
    @include mq(pc) {
      text-align: center!important;
    }
  }
}
._left, ._ta-l {
  text-align: left !important;
}
._right, ._ta-r {
  text-align: right !important;
  &-pc {
    @include mq(pc) {
      text-align: right !important;
    }
  }
}

.opactiy {
  transition: $transition;
  &:hover {
    opacity: $opacity;
  }
}

._fl-l {
  float: left !important;
}
._fl-r {
  float: right !important;
}
._fl-c {
  margin-left: auto !important;
  margin-right: auto !important;
}

._clear {
  clear: both;
}
._clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

._cancel {
  text-decoration: line-through !important;
}

._block,
._d-b {
  display: block !important;
}
._d-i {
  display: inline !important;
}
._d-ib {
  display: inline-block !important;
}
._d-t {
  display: table !important;
  > * {
    display: table-cell;
    vertical-align: top;
  }
}
._d-n,
._hidden {
  display: none;
}

._no-border,
._bd-0 {
  border: none !important;
}
._bdt-0 {
  border-top: none !important;
}
._bdb-0 {
  border-bottom: none !important;
}
._bdr-0 {
  border-right: none !important;
}
._bdl-0 {
  border-left: none !important;
}

._no-bg {
  background: none !important;
}

._radius {
  border-radius: 4px !important;
}

._m-0,
._m0,
._no-m {
  margin: 0 !important;
}

._mt--0 {
  margin-top: 0 !important;
}

._p-0,
._p0,
._no-p {
  padding: 0 !important;
}

._pt-0 {
  padding-top: 0!important;
}
._mt-0 {
  margin-top: 0!important;
}



._u-en {
  font-family: 'Tangerine', cursive;
}



@include mq(pc) {
  @for $num from 1 through 150 {
    $margins: top, right, bottom, left;
    $classNames: mt, mr, mb, ml;
    @each $margin in $margins {
      $i: index($margins, $margin);
      $class: nth($classNames, $i);
      ._#{$class}-pc#{$num} {
        margin-#{$margin}: #{$num}px;
      }
    }
  }
}
@include mq(sp) {
  @for $num from 1 through 150 {
    $margins: top, right, bottom, left;
    $classNames: mt, mr, mb, ml;
    @each $margin in $margins {
      $i: index($margins, $margin);
      $class: nth($classNames, $i);
      ._#{$class}-sp#{$num} {
        $m: $num / 750 * 100;
        margin-#{$margin}: #{$m}vw;
      }
    }
  }
}

.hover {
  &-opacity {
    &:hover {
      transition: $transition;
      opacity: $opacity;
    }
  }
}
