.p-hero {
  @include mq(pc) {
    height: 610px;
    background: url(../img/bg_hero_01.jpg) no-repeat center center / cover;
  }
  &-inner {
    @include mq(pc) {
      padding: 50px 70px 0;
      max-width: 1400px;
      margin: 0 auto;
    }
  }
  &-catch {
    &__intro {
      margin-top: 60px;
      font-size: 1.8rem;
      line-height: 2;
      @include mq(sp) {
        margin-top: 5.3vw;
        padding-left: 3.3vw;
        padding-right: 3.3vw;
        font-size: 2.8rem;
        line-height: 1.57;
      }
    }
  }
}

.p-message {
  &.c-sec {
    @include mq(sp) {
      padding-top: 60 / $spDesign + vw;
    }
  }
  &__inner {
    // position: relative;

  }
  &__txt {
    @include mq(pc) {
      position: relative;
      margin-left: auto;
      @include fb_width(480px);
    }
    @include mq(sp) {
      margin-top: 50 / $spDesign + vw;
    }
    &:before {
      position: absolute;
      content: "";
      background: url(../img/pho_greeting_01.png) no-repeat 0 0 / 100%;
      width: 640px;
      height: 440px;
      right: calc(100% + 40px);
      top: 0;
    }    
  }
  // &__photo {
  //   @include mq(pc) {
  //     @include fb_width(640px);
  //   }
  // }
}

.p-feature {
  &-box {
    &:nth-of-type(2) {
      margin-top: 50px;
      @include mq(sp) {
      margin-top: 50 / $spDesign + vw;
    }
    }
  }
  &__photo {
    @include mq(pc) {
      @include fb_width(40%);
      flex-shrink: 0;
    }
  }
  &__txt {
    @include mq(pc) {
      @include fb_width(55.3%);
      margin-top: -9.57px;
    }
    @include mq(sp) {
      margin-top: 50 / $spDesign + vw;
    }
  }
}

.p-profile {
  &__photo {
    @include mq(pc) {
      @include fb_width(33%);
      flex-shrink: 0;
    }
    @include mq(sp) {
      width: 456 / $spDesign + vw;
      margin: 0 auto;
    }
  }
  &__txt {
    @include mq(pc) {
      margin-top: -9.57px;
      margin-left: 40px;
    }
  }
  .c-sec__txt {
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 50 / $spDesign + vw;
    }
  }
  &__name {
    font-size: 2.2rem;
    @include mq(sp) {
      margin-top: 30 / $spDesign + vw;
      font-size: 3.6rem;
      text-align: center;
      line-height: 1.3;
    }
    &-en {
      display: block;
      font-size: 1.2rem;
      @include mq(sp) {
        font-size: 1.8rem;
      }
    }
  }

}

.p-achievements {
  background: url('../img/bg_achievements_01_pc.jpg') no-repeat center center / cover;
  &-info {
    text-align: center;
  }
}

.p-course {
  padding-top: 90px;
  @include mq(sp) {
    padding-top: 90 / $spDesign + vw;
  }
  &-table {
    width: 100%;
    background: #ffffff;
    th {
      span {
        display: inline-block;
        @include mq(pc) {
          margin-left: 1em;
        }
      }
    }
    th,td {
      padding: 10px 0;
      border: 1px solid #cdcdcd;
      table-layout: fixed;
      width: (100% / 3);
      @include mq(sp) {
        padding: 9 / $spDesign + vw 0;
        vertical-align: middle;
      }
    }
  }
  &-intro {
    text-align: center;
  }
}

.p-insta {
  padding: 6px 0 0;
  background: $colorSecondary;
  @include mq(sp) {
    padding: 10 / $spDesign + vw 0 0;
  }
}

.p-access {
  @include mq(pc) {
    /*background: url('../img/bg_access_01_pc.png') no-repeat right bottom;*/
    background-size: 600px 100%;
  }
  &__logo {
    width: 28%;
    margin: 0 auto;
    @include mq(sp) {
      width: 416 / $spDesign + vw;
    }
  }
  &-info {
    margin-top: 30px;
    @include mq(sp) {
      margin-top: 50 / $spDesign + vw;
    }
    &__ttl {
      font-size: 1.8rem;
      @include mq(sp) {
        font-size: 3rem;
      }
    }
    dd {
      margin-top: 30px;
      @include mq(sp) {
        margin-top: 30 / $spDesign + vw;
      }
    }
  }
}

.p-contact {
  @include mq(pc) {
    background:linear-gradient(90deg,$colorSecondary 0%,$colorSecondary 50%,$colorPrimary 50%,$colorPrimary 100%);
  }
  @include mq(sp) {
    background: $colorSecondary;
    padding-bottom: 0;
  }
  &-box {
    @include mq(pc) {
      @include fb_width(50%);
    }
    &--free {
      @include mq(sp) {
        padding-bottom: 12vw;
      }
    }
    &--conatct {
      @include mq(sp) {
        background: $colorPrimary;
        padding-top: 12vw;
        padding-bottom: 12vw;
        margin-left: -3.3vw;
        margin-right: -3.3vw;
      }   
    }
    &__tel {
      margin-top: 30px;
      @include mq(sp) {
        font-size: 2rem;
        margin-top: 25 / $spDesign + vw;
      }
      &-number {
        font-size: 3.6rem;
        @include mq(sp) {
          font-size: 4.8rem;
        }
        span {
          position: relative;
          padding-left: 40px;
          display: inline-block;
          @include mq(sp) {
            padding-left: 52 / $spDesign + vw;
          }
          &:before {
            position: absolute;
            content:"";
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: url(../img/ico_phone.png) no-repeat 0 0 / cover;
            width: 26px;
            height: 38px;
            @include mq(sp) {
              width: 34 / $spDesign + vw;
              height: 50 / $spDesign + vw;
            }
          }          
        }
        & + p {
          font-size: 1.4rem;
          @include mq(sp) {
            font-size: 3rem;
          }
        }
      }
    }
  }
  &-mail {
    margin-top: 20px;
    @include mq(sp) {
      margin-top: 20 / $spDesign + vw;
    }
    span {
      position: relative;
      padding-left: 43px;
      font-size: 2.2rem;
      @include mq(sp) {
        padding-left: 60 / $spDesign + vw;
        font-size: 3rem;
      }
      &:before {
        position: absolute;
        content:"";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(../img/ico_mail.png) no-repeat 0 0 / cover;
        width: 28px;
        height: 20px;
        @include mq(sp) {
          width: 44 / $spDesign + vw;
          height: 31 / $spDesign + vw;
        }        
      }
    }
  }
}