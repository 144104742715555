@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/NotoSansJP-Light.otf") format("opentype");
}
@font-face {
  font-family: 'Tangerine';
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Tangerine-Regular.ttf") format("truetype");
}